import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['element'];

  connect() {
    this.observeElements();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  observeElements() {
    this.elementTargets.forEach((element) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              element.classList.add('opacity-100', 'transition-opacity', 'duration-500');
              observer.unobserve(element);
            }
          });
        },
        {
          root: null, // Use the viewport as the root
          rootMargin: '0px',
          threshold: 0.1, // Adjust as needed
        }
      );

      observer.observe(element);
    });
  }
}